(function () {
    'use strict';

    angular
        .module('app.administration.awayBudgetPlan.awayBudgetPlanCreate')
        .controller('AwayBudgetPlanCreateController', AwayBudgetPlanCreateController);

    function AwayBudgetPlanCreateController(log, paramHelper, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $timeout, $filter, $state) {
        $rootScope.title = gettextCatalog.getString('AwayBudgetPlanCreate');
        var vm = this;
        $rootScope.vm = vm;
        vm.tervId = -1;
        vm.tenyId = -1;
        vm.printOpened = false;
        vm.title = '';
        vm.subtitle = '';
        vm.data = {};
        vm.data.Id = 0;
        vm.currentUserId = authenticationService.getAuthData().id;
        vm.saveIsBusy = false;
        vm.forceDisableEdit = false; // Ha ez true, akkor nem lehet módosítani sehogy (olyan terv, amihez van tény)
        disableEverything();
        vm.SZAKOSZTVEZ = false;
        vm.GAZDASAGI = false;
        vm.TECHIGAZGATO = false;

        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.coachList = [];
        vm.allusers = [];

        vm.athleteList = [];
        vm.otherPersonName = '';
        vm.otherPersonYear = '';
        vm.sorszam = 1;
        vm.szint = 0;
        // Plans Grid
        vm.reportParameters = [];
        vm.busOwners = [];
        vm.busLists = [];
        vm.columns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {
                field: 'Sorszam',
                headerText: gettextCatalog.getString('PlanNumber'),
                isPrimaryKey: true,
                isIdentity: true,
                width: 90,
                allowEditing: false
            },
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('Name'),
                width: 120,
                allowEditing: false
            },
            {
                field: 'SzuletesiEv',
                headerText: gettextCatalog.getString('BirthYear'),
                width: 120,
                allowEditing: false
            },
            {
                field: 'UtazasiKoltseg',
                headerText: gettextCatalog.getString('TravelCost'),
                width: 120,
                editType: 'numericedit',
                edit: {params: {decimals: 2, showSpinButton: false}},
                format: 'N2',
            },
            {
                field: 'Etkezes',
                headerText: gettextCatalog.getString('Meal'),
                width: 120,
                editType: 'numericedit',
                edit: {params: {decimals: 2, showSpinButton: false}},
                format: 'N2',
            },
            {
                field: 'Szallas',
                headerText: gettextCatalog.getString('Accommodation'),
                width: 120,
                editType: 'numericedit',
                edit: {params: {decimals: 2, showSpinButton: false}},
                format: 'N2',
            },
            {
                field: 'NevezesiDij',
                headerText: gettextCatalog.getString('RegistrationFee'),
                width: 120,
                editType: 'numericedit',
                edit: {params: {decimals: 2, showSpinButton: false}},
                format: 'N2',
            },
            {
                field: 'KikuldetesiKoltseg',
                headerText: gettextCatalog.getString('SecondmentCost'),
                width: 120,
                editType: 'numericedit',
                edit: {params: {decimals: 2, showSpinButton: false}},
                format: 'N2',
            },
            {
                field: 'Egyeb',
                headerText: gettextCatalog.getString('Other'),
                width: 120,
                editType: 'numericedit',
                edit: {params: {decimals: 2, showSpinButton: false}},
                format: 'N2',
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowExcelExport: true,
            allowTextWrap: true,
            editSettings: {
                allowEditing: vm.allowEdit,
                allowDeleting: vm.allowEdit,
                allowSelection: vm.allowEdit,
                mode: 'Batch',
                showConfirmDialog: false
            },
            selectionSettings: {type: 'multiple', mode: 'Row'},
            locale: 'hu-HU',
            gridLines: 'Both',
            columns: vm.columns,
            cellSave: cellsave,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.coachDataBound = coachDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.athleteSelect = athleteSelect;
        vm.coachSelect = coachSelect;
        vm.edzoSelect = edzoSelect;

        vm.addAthlete = addAthlete;
        vm.addAllAthlete = addAllAthlete;
        vm.addUser = addUser;
        vm.addOtherPersonName = addOtherPersonName;

        vm.travelCostDivide = travelCostDivide;
        vm.mealDivide = mealDivide;
        vm.accommodationDivide = accommodationDivide;
        vm.registrationFeeDivide = registrationFeeDivide;
        vm.secondmentCostDivide = secondmentCostDivide;
        vm.otherDivide = otherDivide;

        vm.UtazasiKoltsegOsszegChange = UtazasiKoltsegOsszegChange;
        vm.EtkezesOsszegChange = EtkezesOsszegChange;
        vm.SzallasOsszegChange = SzallasOsszegChange;
        vm.NevezesiOsszegChange = NevezesiOsszegChange;
        vm.KikuldetesiKoltsegOsszegChange = KikuldetesiKoltsegOsszegChange;
        vm.EgyebOsszegChange = EgyebOsszegChange;
        vm.excel = excel;

        vm.cellsave = cellsave;
        vm.ADMIN = false;
        vm.JOVIDEGENSZAK = false;
        vm.TORLIDEGENKOLTSTERV = false;
        vm.JOVIDEGENGAZD = false;
        vm.JOVIDEGENVEZ = false;
        vm.print = print;
        vm.finalize = finalize;
        vm.close = close;
        vm.open = open;
        vm.deleteSelected = deleteSelected;
        vm.openPlan = openPlan;
        vm.openFact = openFact;
        vm.deletePlan = deletePlan;

        vm.enableEdit = enableEdit;

        vm.save = save;
        vm.IWillAccept1 = IWillAccept1;
        vm.IWillAccept2 = IWillAccept2;
        vm.IWillAccept3 = IWillAccept3;
        vm.accept1 = accept1;
        vm.accept2 = accept2;
        vm.accept3 = accept3;
        vm.UndoAccept1 = UndoAccept1;
        vm.UndoAccept2 = UndoAccept2;
        vm.UndoAccept3 = UndoAccept3;

        vm.moreAgeGroups = [];

        activate();

        function activate() {
            var promises = [
                authenticationService.getRight('MEGTIDEGENKOLTSTERV'),
                authenticationService.getRight('MODIDEGENKOLTSTERV'),
                authenticationService.getRight('UJIDEGENKOLTSTERV'),
                authenticationService.getRight('JOVAHAGYIDEGENKOLTSTERV'),
                paramHelper.getParams([], ['awayBudgetPlan.planId', 'awayBudgetPlan.planSection', 'awayBudgetPlan.planAgeGroup']),
                getSections(),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('GAZDASAGI'),
                authenticationService.getRight('TECHIGAZGATO'),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('JOVIDEGENSZAK'),
                authenticationService.getRight('JOVIDEGENGAZD'),
                authenticationService.getRight('JOVIDEGENVEZ'),
                authenticationService.getRight('TORLIDEGENKOLTSTERV'),
                authenticationService.getRight('VEGLIDEGENKOLTSTERV')
            ];
            $q.all(promises).then(function (results) {
                if (!results[0] && results[1] && results[2]) {
                    log.permissionError(true);
                }
                vm.MEGTIDEGENKOLTSTERV = results[0];
                vm.MODIDEGENKOLTSTERV = results[1];
                vm.UJIDEGENKOLTSTERV = results[2];
                vm.JOVAHAGYIDEGENKOLTSTERV = results[3];
                vm.SZAKOSZTVEZ = results[6];
                vm.GAZDASAGI = results[7];
                vm.TECHIGAZGATO = results[8];
                vm.ADMIN = (results[9] || results[13]); // Admin vagy Gazdasági
                vm.JOVIDEGENSZAK = results[10];
                vm.JOVIDEGENGAZD = results[11];
                vm.JOVIDEGENVEZ = results[12];
                vm.TORLIDEGENKOLTSTERV = results[13];
                vm.VEGLIDEGENKOLTSTERV = results[14];
                var tervId = results[4]['awayBudgetPlan.planId'];

                disableEverything();
                initAgeGroupDropDown();
                initAthleteDropDownMS();
                initUserDropDownMS();
                getBusOwners();
                getBuses();
                //getAllUsers();
                if (tervId && tervId > 0) {
                    if (!vm.MODIDEGENKOLTSTERV) {
                        $timeout(function () {
                            vm.allowEdit = false;
                            $scope.$apply();
                        }, 0);
                    }
                    LoadPlan(tervId);
                } else {
                    vm.title = gettextCatalog.getString('DetailedPlan');
                    if (!vm.UJIDEGENKOLTSTERV) {
                        log.permissionError(true);
                    }
                    vm.data.SzakosztalyId = (results[4]['awayBudgetPlan.planSection'] !== null && parseInt(results[4]['awayBudgetPlan.planSection']) > 0) ? results[4]['awayBudgetPlan.planSection'] : null;
                    if (results[4]['awayBudgetPlan.planAgeGroup'] !== null && parseInt(results[4]['awayBudgetPlan.planAgeGroup']) > 0) {
                        vm.data.KorosztalyId = results[4]['awayBudgetPlan.planAgeGroup'];
                    } else {
                        vm.data.KorosztalyId = null;
                    }
                }
            });
        }

        function LoadPlan(Id) {
            disableEverything();
            dataservice.GetAwayBudgetPlan(Id).then(function (resp) {
                paramHelper.setParams([{'awayBudgetPlan.planId': Id}]);
                vm.szint = resp.Szint;
                if (!resp.Teny) {
                    vm.terv = true;
                    vm.teny = false;
                    vm.tenyId = resp.TenyId;
                    vm.forceDisableEdit = resp.retValue.Veglegesitve !== null;
                    enableEdit(resp.retValue.Lezarva === null);
                }
                if (!resp.Terv) {
                    vm.terv = false;
                    vm.teny = true;
                    vm.tervId = resp.TervId;
                    vm.forceDisableEdit = false;
                    enableEdit(resp.retValue.Lezarva === null);
                }
                vm.subtitle1 = resp.Jovahagyo1;
                vm.subtitle2 = resp.Jovahagyo2;
                vm.subtitle3 = resp.Jovahagyo3;
                // Megnyitáskor akkor van letiltva a szerkesztés ha: edző lezárta, vagy nem ugyanaz nyitja meg aki csinálta,
                //  ÉS jóvá van hagyva valamilyen szinten && (vm.data.Jovahagyta1Datum || vm.data.Jovahagyta2Datum || vm.data.Jovahagyta3Datum) EZis kell ???
                if (resp.retValue.Lezarva || vm.data.EdzoId != vm.currentUserId) {
                    enableEdit(false);
                }
                // Ha az edző sajátsa, vagy ADMIN
                if (resp.retValue.EdzoId == vm.currentUserId || vm.ADMIN || vm.SZAKOSZTVEZ) {
                    if (resp.retValue.Lezarva) {
                        vm.allowOpen = true;
                        enableEdit(false);
                    } else {
                        vm.allowClose = true;
                        enableEdit(true);
                    }
                }
                if (resp.retValue.Vegleges) {
                    vm.title = gettextCatalog.getString('DetailedFinal');
                } else {
                    vm.title = gettextCatalog.getString('DetailedPlan');
                }
                if (resp.retValue.Sorszam > 0) {
                    vm.title += ' - ' + gettextCatalog.getString('Number') + ': ' + resp.retValue.Sorszam;
                }
                vm.data = resp.retValue;
                vm.Terv = resp.Terv;
                vm.data.EdzoIdValue = resp.retValue.EdzoId;
                vm.allowFinalize = resp.Veglegesithet;
                vm.UserName = resp.EdzoNeve;
                vm.EdzoElerhetosege = resp.EdzoElerhetosege;
                grid.dataSource = resp.people;
                if (grid.dataSource.length > 0) {
                    vm.sorszam = grid.dataSource[grid.dataSource.length - 1].Sorszam + 1;
                } else {
                    vm.sorszam = 1;
                }
                setButtons();
            });
        }

        function disableEverything() {
            vm.allowEdit = true;
            vm.allowFinalize = false;
            vm.allowClose = false;
            vm.allowOpen = false;
            vm.allowIWillAccept1 = false;
            vm.allowIWillAccept2 = false;
            vm.allowIWillAccept3 = false;
            vm.allowAccept1 = false;
            vm.allowAccept2 = false;
            vm.allowAccept3 = false;
            vm.allowUndoAccept1 = false;
            vm.allowUndoAccept2 = false;
            vm.allowUndoAccept3 = false;
            vm.Accept1Enabled = false;
            vm.Accept2Enabled = false;
            vm.Accept3Enabled = false;
            vm.UndoAccept1Enabled = false;
            vm.UndoAccept2Enabled = false;
            vm.UndoAccept3Enabled = false;
        }

        function setButtons() {
            switch (vm.szint) {
                case 0:
                    if (vm.JOVIDEGENSZAK) {
                        vm.Accept1Enabled = true;
                    }
                    if (vm.JOVIDEGENGAZD) {
                        vm.Accept2Enabled = false;
                    }
                    if (vm.JOVIDEGENVEZ) {
                        vm.Accept3Enabled = false;
                    }
                    break;
                case 1:
                    if (vm.JOVIDEGENSZAK) {
                        vm.UndoAccept1Enabled = true;
                        vm.Accept1Enabled = false;
                    }
                    if (vm.JOVIDEGENGAZD) {
                        vm.Accept2Enabled = true;
                    }
                    if (vm.JOVIDEGENVEZ) {
                        vm.Accept3Enabled = false;
                    }
                    break;
                case 2:
                    if (vm.JOVIDEGENSZAK) {
                        vm.Accept1Enabled = false;
                    }
                    if (vm.JOVIDEGENGAZD) {
                        vm.UndoAccept2Enabled = true;
                        vm.Accept2Enabled = false;
                    }
                    if (vm.JOVIDEGENVEZ) {
                        vm.Accept3Enabled = true;
                    }
                    break;
                case 3:
                    if (vm.JOVIDEGENSZAK) {
                        vm.Accept1Enabled = false;
                    }
                    if (vm.JOVIDEGENGAZD) {
                        vm.Accept2Enabled = false;
                    }
                    if (vm.JOVIDEGENVEZ) {
                        vm.UndoAccept3Enabled = true;
                        vm.Accept3Enabled = false;
                    }
                    break;
            }
            // Ha levan zárva edző által
            if (vm.data.Lezarva) {
                // Szakmai
                // ő az aki már jóvahagyta
                if (vm.currentUserId == vm.data.Jovahagyta1Id) {
                    // és nem vonta vissza akkor visszavonhatja
                    if (vm.data.Jovahagyta1Datum) {
                        vm.allowUndoAccept1 = true;
                    }
                    //de visszavonta akkor Jóváhagyhatja újra
                    else {
                        vm.allowAccept1 = true;
                    }
                }
                // ÉS még nem hagyta jóvá akkor kijelölheti magát jóváhagyónak
                if (!vm.data.Jovahagyta1Id) {
                    vm.allowIWillAccept1 = true;
                }

                // Gazdasági
                if (vm.currentUserId == vm.data.Jovahagyta2Id) {
                    if (vm.data.Jovahagyta2Datum) {
                        vm.allowUndoAccept2 = true;
                    } else {
                        vm.allowAccept2 = true;
                    }
                }
                if (!vm.data.Jovahagyta2Id) {
                    vm.allowIWillAccept2 = true;
                }

                // Vezetői
                if (vm.currentUserId == vm.data.Jovahagyta3Id) {
                    if (vm.data.Jovahagyta3Datum) {
                        vm.allowUndoAccept3 = true;
                    } else {
                        vm.allowAccept3 = true;
                    }
                }
                if (!vm.data.Jovahagyta3Id) {
                    vm.allowIWillAccept3 = true;
                }
            }
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            $('#sectionDropDown').ejDropDownList('selectItemByValue', vm.data.SzakosztalyId);
        }

        function sectionSelect(args) {
            vm.data.SzakosztalyId = args.value;
            vm.athleteList = [];
            getAgeGroups(args.value);
        }

        function getAgeGroups(sectionId) {
            dataservice.ageGroupDropDownList(sectionId)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: -1, text: gettextCatalog.getString('All')});
                    }
                    vm.ageGroupList = data.itemsList;
                    initAgeGroupDropDown();
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.data.KorosztalyId);
            }
        }

        function ageGroupSelect(args) {
            vm.data.KorosztalyId = args.value;
            getAthleteList(args.value);
            getCoachList(args.value);
        }

        function getAthleteList(ageGroupId, AgeGroups) {
            if (!AgeGroups) {
                AgeGroups = [];
            }
            AgeGroups.push(ageGroupId);
            return dataservice.getIdegenbeliKoltsegvetesiTervSportoloList(vm.data.SzakosztalyId, AgeGroups)
                .then(function (data) {
                    vm.athleteList = data.itemsList;
                    initAthleteDropDownMS();
                });
        }

        function getCoachList(ageGroupId) {
            return dataservice.getIdegenbeliKoltsegvetesiTervEdzoList(vm.data.SzakosztalyId, ageGroupId)
                .then(function (data) {
                    vm.coachList = data.itemsList;
                    initUserDropDownMS();
                });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                $('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                $('#coachDropDown').ejDropDownList('selectItemByValue', vm.data.EdzoIdValue);
            }
        }

        // Ez a terv tulajdonosa
        function edzoSelect(args) {
            vm.data.EdzoId = args.value;
            vm.UserName = args.text;
            dataservice.getCoachContant(vm.data.EdzoId).then(function (response) {
                vm.EdzoElerhetosege = response.data;
            });
        }

        function athleteSelect(args) {
            vm.selectedAthlete = vm.athleteList[args.itemId];
            vm.selectedAthlete.index = args.itemId;
        }

        function coachSelect(args) {
            vm.selectedCoach = vm.coachList[args.itemId];
            vm.selectedCoach.index = args.itemId;
        }

        function addAthlete() {
            var selectedItems = $('#AthleteDropDownMS').data('ejDropDownList').getSelectedItem();
            for (var i = 0; i < selectedItems.length; i++) {
                var index = $filter('getIndex')(vm.athleteList, 'value', Number(selectedItems[i].dataset.value));
                var existing = $filter('getIndex')(grid.dataSource, 'SportoloId', vm.athleteList[index].value);
                if (existing === false) {
                    grid.dataSource.push({
                        SportoloId: vm.athleteList[index].value,
                        FelhasznaloId: null,
                        Sorszam: vm.sorszam++,
                        Nev: vm.athleteList[index].text,
                        SzuletesiEv: vm.athleteList[index].szuletesiEv,
                        UtazasiKoltseg: 0,
                        Etkezes: 0,
                        Szallas: 0,
                        NevezesiDij: 0,
                        KikuldetesiKoltseg: 0,
                        Egyeb: 0
                    });
                }
            }
            angular.element('#AthleteDropDownMS').ejDropDownList('uncheckAll');
            grid.refresh();
            vm.selectedAthlete = null;
            $scope.$apply();
        }

        function addAllAthlete() {
            if (vm.athleteList.length <= 0) {
                return;
            }
            vm.athleteList.forEach(function (t) {
                var existing = $filter('getIndex')(grid.dataSource, 'SportoloId', t.value);
                if (existing === false) {
                    grid.dataSource.push({
                        SportoloId: t.value,
                        FelhasznaloId: null,
                        Sorszam: vm.sorszam++,
                        Nev: t.text,
                        SzuletesiEv: t.szuletesiEv,
                        UtazasiKoltseg: 0,
                        Etkezes: 0,
                        Szallas: 0,
                        NevezesiDij: 0,
                        KikuldetesiKoltseg: 0,
                        Egyeb: 0
                    });
                }
            });
            grid.refresh();
            vm.selectedAthlete = null;
            $scope.$apply();
        }

        function addUser() {
            var selectedItems = $('#UserDropDownMS').data('ejDropDownList').getSelectedItem();
            for (var i = 0; i < selectedItems.length; i++) {
                var index = $filter('getIndex')(vm.coachList, 'value', Number(selectedItems[i].dataset.value));
                var existing = $filter('getIndex')(grid.dataSource, 'FelhasznaloId', vm.coachList[index].value);
                if (existing === false) {
                    grid.dataSource.push({
                        SportoloId: null,
                        FelhasznaloId: vm.coachList[index].value,
                        Sorszam: vm.sorszam++,
                        Nev: vm.coachList[index].text,
                        SzuletesiEv: vm.coachList[index].szuletesiEv,
                        UtazasiKoltseg: 0,
                        Etkezes: 0,
                        Szallas: 0,
                        NevezesiDij: 0,
                        KikuldetesiKoltseg: 0,
                        Egyeb: 0
                    });
                }
            }
            angular.element('#UserDropDownMS').ejDropDownList('uncheckAll');
            grid.refresh();
            vm.selectedCoach = null;
            $scope.$apply();
        }

        function addOtherPersonName() {
            if (!vm.otherPersonName || !vm.otherPersonYear) {
                swal({
                    title: gettextCatalog.getString('PersonNameOrPersonYearEmpty'),
                    type: 'error',
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Ok'),
                });
                return;
            }
            grid.dataSource.push({
                SportoloId: null,
                FelhasznaloId: null,
                Sorszam: vm.sorszam++,
                Nev: vm.otherPersonName,
                SzuletesiEv: vm.otherPersonYear,
                UtazasiKoltseg: 0,
                Etkezes: 0,
                Szallas: 0,
                NevezesiDij: 0,
                KikuldetesiKoltseg: 0,
                Egyeb: 0
            });
            vm.otherPersonName = '';
            vm.otherPersonYear = '';
            grid.refresh();
            $scope.$apply();
        }

        function ToFixedTwo(n) {
            return n;
            var f = parseFloat(n).toFixed(2);
            f = parseFloat(f).toLocaleString('en');
            f = f.replace(/,/g, ' ');
            return f;
        }

        function travelCostDivide() {
            var result = 0;
            if (vm.data.UtazasiKoltsegOsszeg && vm.data.UtazasiKoltsegOsszeg !== 0) {
                result = ToFixedTwo(vm.data.UtazasiKoltsegOsszeg / grid.dataSource.length);
            }
            grid.dataSource.forEach(function (t) {
                t.UtazasiKoltseg = result;
            });
            grid.refresh();
        }

        function mealDivide() {
            var result = 0;
            if (vm.data.EtkezesOsszeg && vm.data.EtkezesOsszeg != 0) {
                result = ToFixedTwo(vm.data.EtkezesOsszeg / grid.dataSource.length);
            }
            grid.dataSource.forEach(function (t) {
                t.Etkezes = result;
            });
            grid.refresh();
        }

        function accommodationDivide() {
            var result = 0;
            if (vm.data.SzallasOsszeg && vm.data.SzallasOsszeg != 0) {
                result = ToFixedTwo(vm.data.SzallasOsszeg / grid.dataSource.length);
            }
            grid.dataSource.forEach(function (t) {
                t.Szallas = result;
            });
            grid.refresh();
        }

        function registrationFeeDivide() {
            var result = 0;
            if (vm.data.NevezesiOsszeg && vm.data.NevezesiOsszeg != 0) {
                result = ToFixedTwo(vm.data.NevezesiOsszeg / grid.dataSource.length);
            }
            grid.dataSource.forEach(function (t) {
                t.NevezesiDij = result;
            });
            grid.refresh();
        }

        function secondmentCostDivide() {
            var result = 0;
            if (vm.data.KikuldetesiKoltsegOsszeg && vm.data.KikuldetesiKoltsegOsszeg != 0) {
                result = ToFixedTwo(vm.data.KikuldetesiKoltsegOsszeg / grid.dataSource.length);
            }
            grid.dataSource.forEach(function (t) {
                t.KikuldetesiKoltseg = result;
            });
            grid.refresh();
        }

        function otherDivide() {
            var result = 0;
            if (vm.data.EgyebOsszeg && vm.data.EgyebOsszeg != 0) {
                result = ToFixedTwo(vm.data.EgyebOsszeg / grid.dataSource.length);
            }
            grid.dataSource.forEach(function (t) {
                t.Egyeb = result;
            });
            grid.refresh();
        }

        function UtazasiKoltsegOsszegChange(args) {
            vm.data.OsszesenOszeg = args.value + vm.data.EtkezesOsszeg + vm.data.SzallasOsszeg + vm.data.NevezesiOsszeg + vm.data.KikuldetesiKoltsegOsszeg + vm.data.EgyebOsszeg;
        }

        function EtkezesOsszegChange(args) {
            vm.data.OsszesenOszeg = vm.data.UtazasiKoltsegOsszeg + args.value + vm.data.SzallasOsszeg + vm.data.NevezesiOsszeg + vm.data.KikuldetesiKoltsegOsszeg + vm.data.EgyebOsszeg;
        }

        function SzallasOsszegChange(args) {
            vm.data.OsszesenOszeg = vm.data.UtazasiKoltsegOsszeg + vm.data.EtkezesOsszeg + args.value + vm.data.NevezesiOsszeg + vm.data.KikuldetesiKoltsegOsszeg + vm.data.EgyebOsszeg;
        }

        function NevezesiOsszegChange(args) {
            vm.data.OsszesenOszeg = vm.data.UtazasiKoltsegOsszeg + vm.data.EtkezesOsszeg + vm.data.SzallasOsszeg + args.value + vm.data.KikuldetesiKoltsegOsszeg + vm.data.EgyebOsszeg;
        }

        function KikuldetesiKoltsegOsszegChange(args) {
            vm.data.OsszesenOszeg = vm.data.UtazasiKoltsegOsszeg + vm.data.EtkezesOsszeg + vm.data.SzallasOsszeg + vm.data.NevezesiOsszeg + args.value + vm.data.EgyebOsszeg;
        }

        function EgyebOsszegChange(args) {
            vm.data.OsszesenOszeg = vm.data.UtazasiKoltsegOsszeg + vm.data.EtkezesOsszeg + vm.data.SzallasOsszeg + vm.data.NevezesiOsszeg + vm.data.KikuldetesiKoltsegOsszeg + args.value;
        }

        function reCalculate(args, column, property) {
            return $q(function () {
                grid.dataSource[args.rowData.Sorszam - 1][column] = args.value;
                vm.data[property] = 0;
                grid.dataSource.forEach(function (t) {
                    if (!isNaN(parseFloat(t[column]))) {
                        vm.data[property] += parseFloat(t[column]);
                    }
                });
            });
        }

        function cellsave(args) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('Sorszam', 'equal', args.rowData.Sorszam)).then(function (result) {
                result.result[0][args.columnName] = args.value;
                grid.refresh();
            });

            if (args.columnName === 'UtazasiKoltseg') {
                args.cancel = true;
                reCalculate(args, 'UtazasiKoltseg', 'UtazasiKoltsegOsszeg').then(function () {
                    args.cancel = false;
                });
            }
            if (args.columnName === 'Etkezes') {
                args.cancel = true;
                reCalculate(args, 'Etkezes', 'EtkezesOsszeg').then(function () {
                    args.cancel = false;
                });
            }
            if (args.columnName === 'Szallas') {
                args.cancel = true;
                reCalculate(args, 'Szallas', 'SzallasOsszeg').then(function () {
                    args.cancel = false;
                });
            }
            if (args.columnName === 'NevezesiDij') {
                args.cancel = true;
                reCalculate(args, 'NevezesiDij', 'NevezesiOsszeg').then(function () {
                    args.cancel = false;
                });
            }
            if (args.columnName === 'KikuldetesiKoltseg') {
                args.cancel = true;
                reCalculate(args, 'KikuldetesiKoltseg', 'KikuldetesiKoltsegOsszeg').then(function () {
                    args.cancel = false;
                });
            }
            if (args.columnName === 'Egyeb') {
                args.cancel = true;
                reCalculate(args, 'Egyeb', 'EgyebOsszeg').then(function () {
                    args.cancel = false;
                });
            }

            args.cancel = false;
            $scope.$apply();
        }

        function save(arg) {
            if (vm.saveIsBusy) {
                log.warningMsg(gettextCatalog.getString('PleaseWaitWhileSaving'));
                return;
            }
            vm.saveIsBusy = true;
            if (vm.allowEdit) {
                grid.editModule.batchSave();
            }
            vm.data.people = grid.dataSource;
            if (arg && arg.target && arg.target.name === 'saveAwayBudgetPlan') {
                arg = 'save';
            }
            ['UtazasiKoltsegOsszeg', 'EtkezesOsszeg', 'SzallasOsszeg', 'NevezesiOsszeg', 'KikuldetesiKoltsegOsszeg', 'EgyebOsszeg']
                .forEach(function (f) {
                    if (!vm.data.hasOwnProperty(f) || vm.data[f] === null || vm.data[f] === '' || isNaN(vm.data[f])) {
                        vm.data[f] = 0;
                    }
                });
            $timeout(function () {
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                    vm.saveIsBusy = false;
                    return;
                }
                return dataservice.SaveAwayBudgetPlan(vm.data).then(function (response) {
                    vm.data.Id = response.retValue;
                    log.successMsg('SaveCompleted');
                    switch (arg) {
                        case 'save':
                            LoadPlan(vm.data.Id);
                            vm.saveIsBusy = false;
                            break;
                        case 'print':
                            vm.reportParameters = {
                                Header: vm.title,
                                Szakosztaly: angular.element('#sectionDropDown').data('ejDropDownList').getSelectedItem()[0].innerText,
                                Korosztaly: angular.element('#ageGroupDropDown').data('ejDropDownList').getSelectedItem()[0].innerText,
                                Edzo: vm.UserName,
                                EdzoElerhetosege: vm.EdzoElerhetosege == undefined ? ' ' : vm.EdzoElerhetosege,
                                Tervadat: vm.data.Vegleges ? 'Végleges' : 'Tervadat',
                                UtaslistaNev: vm.data.Vegleges ? 'Végleges utaslista' : 'Tervezett utaslista',
                                Sorszam: vm.data.Sorszam == undefined ? ' ' : vm.data.Sorszam,
                                FejId: vm.data.Id,
                                Lezaras: vm.data.Lezarva ? vm.data.Lezarva : 'Még nincs lezárva',
                                Jovahagyas1: vm.subtitle1 == undefined ? ' ' : vm.subtitle1,
                                Jovahagyas2: vm.subtitle2 == undefined ? ' ' : vm.subtitle2,
                                Jovahagyas3: vm.subtitle3 == undefined ? ' ' : vm.subtitle3,
                            };
                            if (vm.printOpened) {
                                angular.element('#printDialog').ejDialog('open');
                            } else {
                                vm.printOpened = true;
                                angular.element('#printDialog').ejDialog({
                                    height: '95%',
                                    width: '95%',
                                    enablemodal: 'true',
                                    'position-x': '0',
                                    'position-y': '1',
                                    target: '#mainContent',
                                    enableresize: 'false'
                                });
                            }
                            vm.saveIsBusy = false;
                            break;
                        case 'excel':
                            dataservice.KoltesegvetesiTervExcelExport(vm.data.Id).then(function (response) {
                                var urlCreator = window.URL || window.webkitURL || window.mozURL || window.msURL;
                                if (urlCreator) {
                                    var url = response.data;
                                    var a = document.createElement('a');
                                    document.body.appendChild(a);
                                    a.href = url;
                                    a.click();
                                    window.URL.revokeObjectURL(url);
                                }
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 'finalize':
                            console.log('vm.VEGLIDEGENKOLTSTERV', vm.VEGLIDEGENKOLTSTERV);
                            if (!vm.VEGLIDEGENKOLTSTERV) {
                                log.permissionError();
                                vm.saveIsBusy = false;
                            } else {
                                dataservice.KoltesegvetesiTervVeglegesites(vm.data.Id).then(function (veglegesResponse) {
                                    log.infoMsg('Finalized');
                                    LoadPlan(veglegesResponse.data.id);
                                    vm.saveIsBusy = false;
                                });
                            }
                            break;
                        case 'close':
                            dataservice.KoltesegvetesiTervLezaras(vm.data.Id, true).then(function (lezarasResponse) {
                                log.infoMsg('Closed');
                                vm.allowClose = false;
                                LoadPlan(lezarasResponse.data.id);
                                enableEdit(false);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 'open':
                            dataservice.KoltesegvetesiTervLezaras(vm.data.Id, false).then(function (lezarasResponse) {
                                log.infoMsg('ReOpened');
                                vm.allowOpen = false;
                                LoadPlan(lezarasResponse.data.id);
                                enableEdit(true);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case -1:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept1 = true;
                                vm.allowUndoAccept1 = false;
                                log.infoMsg('AcceptUndone');
                                enableEdit(true);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 1:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept1 = false;
                                log.infoMsg('Approved');
                                enableEdit(false);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 11:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept1 = true;
                                log.infoMsg('ApproverUserSelected');
                                enableEdit(true);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case -2:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept2 = true;
                                vm.allowUndoAccept2 = false;
                                log.infoMsg('AcceptUndone');
                                enableEdit(true);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 2:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept2 = false;
                                log.infoMsg('Approved');
                                enableEdit(false);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 22:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept2 = true;
                                log.infoMsg('ApproverUserSelected');
                                enableEdit(true);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case -3:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept3 = true;
                                vm.allowUndoAccept3 = false;
                                log.infoMsg('AcceptUndone');
                                enableEdit(true);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 3:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept3 = false;
                                log.infoMsg('Approved');
                                enableEdit(false);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                        case 33:
                            dataservice.KoltesegvetesiTervJovahagyas(vm.data.Id, arg).then(function () {
                                vm.allowAccept3 = true;
                                log.infoMsg('ApproverUserSelected');
                                enableEdit(true);
                                LoadPlan(vm.data.Id);
                                vm.saveIsBusy = false;
                            });
                            break;
                    }
                });
            }, 300);
        }

        function IWillAccept1() {
            if (!vm.JOVIDEGENSZAK) {
                log.permissionError();
                return;
            }
            save(11);
        }

        function IWillAccept2() {
            if (!vm.JOVIDEGENGAZD) {
                log.permissionError();
                return;
            }
            save(22);
        }

        function IWillAccept3() {
            if (!vm.JOVIDEGENVEZ) {
                log.permissionError();
                return;
            }
            save(33);
        }

        function excel() {
            save('excel');
        }

        function accept1() {
            save(1);
        }

        function accept2() {
            save(2);
        }

        function accept3() {
            save(3);
        }

        function UndoAccept1() {
            save(-1);
        }

        function UndoAccept2() {
            save(-2);
        }

        function UndoAccept3() {
            save(-3);
        }

        function close() {
            save('close');
        }

        function open() {
            save('open');
        }

        function finalize() {
            swal({
                title: gettextCatalog.getString('Away Budget Plan Finalize Warning!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No'),
                closeOnConfirm: true,
                closeOnCancel: true
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    save('finalize');
                }
            });
        }

        function print() {
            save('print');
        }

        function deleteSelected() {
            var selectedRecords = grid.getSelectedRecords();
            for (var i = selectedRecords.length - 1; i >= 0; i--) {
                var delIdx = $filter('getIndex')(grid.dataSource, 'Sorszam', selectedRecords[i].Sorszam);
                vm.data.UtazasiKoltsegOsszeg -= (!isNaN(selectedRecords[i].UtazasiKoltseg) && Number(selectedRecords[i].UtazasiKoltseg)) ? selectedRecords[i].UtazasiKoltseg : 0;
                vm.data.EtkezesOsszeg -= (!isNaN(selectedRecords[i].Etkezes) && Number(selectedRecords[i].Etkezes)) ? selectedRecords[i].Etkezes : 0;
                vm.data.SzallasOsszeg -= (!isNaN(selectedRecords[i].Szallas) && Number(selectedRecords[i].Szallas)) ? selectedRecords[i].Szallas : 0;
                vm.data.NevezesiOsszeg -= (!isNaN(selectedRecords[i].NevezesiDij) && Number(selectedRecords[i].NevezesiDij)) ? selectedRecords[i].NevezesiDij : 0;
                vm.data.KikuldetesiKoltsegOsszeg -= (!isNaN(selectedRecords[i].KikuldetesiKoltseg) && Number(selectedRecords[i].KikuldetesiKoltseg)) ? selectedRecords[i].KikuldetesiKoltseg : 0;
                vm.data.EgyebOsszeg -= (!isNaN(selectedRecords[i].Egyeb) && Number(selectedRecords[i].Egyeb)) ? selectedRecords[i].Egyeb : 0;
                grid.dataSource.splice(delIdx, 1);
                $scope.$apply();
            }
            if (selectedRecords) {
                $timeout(function () {
                    vm.sorszam = 1;
                    grid.dataSource.forEach(function (t) {
                        t.Sorszam = vm.sorszam++;
                    });
                    grid.refresh();
                }, 333);
            }
        }

        function enableEdit(arg) {
            if (arg && vm.forceDisableEdit) {
                return;
            }
            vm.allowEdit = arg;
            grid.editSettings.allowEditing = arg;
            grid.editSettings.allowSelection = arg;
            grid.editSettings.allowDeleting = arg;
            $timeout(function () {
                $scope.$apply();
            }, 100);
            $('#MoreAgeGroups').ejDropDownList({enabled: arg});
            $('#AthleteDropDownMS').ejDropDownList({enabled: arg});
            $('#UserDropDownMS').ejDropDownList({enabled: arg});
        }

        function openPlan() {
            // megkeresem a véglegesités évével és a tény sorszámával.
            LoadPlan(vm.tervId);
        }

        function openFact() {
            LoadPlan(vm.tenyId);
        }

        function initAgeGroupDropDown() {
            $('#MoreAgeGroups').ejDropDownList({
                dataSource: vm.ageGroupList,
                fields: {text: 'text'},
                showCheckbox: true,
                width: '100%',
                multiSelectMode: ej.MultiSelectMode.Delimiter,
                change: function (arg) {
                    if (arg.isChecked) {
                        if (arg.value == 0) {
                            for (var a = 1; a < vm.ageGroupList.length; a++) {
                                $('#MoreAgeGroups').ejDropDownList('unselectItemByValue', vm.ageGroupList[a].value);
                            }
                        } else {
                            $('#MoreAgeGroups').ejDropDownList('unselectItemByValue', 0);
                        }
                    }
                    var selectedItems = $('#MoreAgeGroups').data('ejDropDownList').getSelectedItem();
                    var moreAgeGroups = [];
                    for (var i = 0; i < selectedItems.length; i++) {
                        moreAgeGroups.push(Number(selectedItems[i].dataset.value));
                    }
                    getAthleteList(vm.data.KorosztalyId, moreAgeGroups);
                }
            });
        }

        function initAthleteDropDownMS() {
            $('#AthleteDropDownMS').ejDropDownList({
                dataSource: vm.athleteList,
                fields: {text: 'text'},
                showCheckbox: true,
                width: '100%',
                multiSelectMode: ej.MultiSelectMode.Delimiter
            });
        }

        function initUserDropDownMS() {
            $('#UserDropDownMS').ejDropDownList({
                dataSource: vm.coachList,
                fields: {text: 'text'},
                showCheckbox: true,
                width: '100%',
                multiSelectMode: ej.MultiSelectMode.Delimiter
            });
        }

        function getBusOwners() {
            dataservice.IdegenKoltsegTervBuszTulaj().then(function (response) {
                vm.busOwners = response.data;
            });
        }

        function getBuses() {
            dataservice.IdegenKoltsegTervBusz().then(function (resp) {
                vm.busLists = resp.data;
            });
        }

        function deletePlan() {
            if (!vm.TORLIDEGENKOLTSTERV) {
                log.permissionError();
                return;
            }
            if (vm.data.Id && vm.data.Id > 0) {
                swal({
                    title: gettextCatalog.getString('Are you sure you want to delete the selected away budget Plan?'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: gettextCatalog.getString('Yes'),
                    cancelButtonText: gettextCatalog.getString('Cancel'),
                    closeOnConfirm: true,
                    closeOnCancel: true
                }).then(function (isConfirm) {
                    if (isConfirm.value) {
                        return dataservice.deleteAwayBudgetPlan(vm.data.Id).then(function (res) {
                            if (res.retValue) {
                                log.successMsg(gettextCatalog.getString('PlanDeleted'));
                                $state.go('administration.awayBudgetPlan.awayBudgetPlanList');
                            }
                        });
                    }
                });
            }
        }
    }
})
();
