(function () {
    'use strict';

    angular
        .module('app.layout')
        .run(function ($trace, $transitions, authenticationService, localStorageService, $q, $rootScope, gettextCatalog, routerHelper, DOMAIN) {
            $rootScope.availableLanguages = ['hu', 'en', 'ro', 'fr'];
            $transitions.onBefore({}, function () {
                return $q(function (resolve) {
                    var states = routerHelper.getStates();

                    function getNavRoutes() {
                        return $q(function (resolve) {
                            var authData = authenticationService.getAuthData();

                            var lang = $rootScope.availableLanguages[0];
                            if (authData) {
                                lang = authenticationService.getAuthData().language;
                            }

                            $rootScope.navRoutes = states.filter(function (r) {
                                if (DOMAIN.justPsycho === 1) {
                                    return r.settings && r.settings.level && r.settings.level === 1 && !r.settings.parentId && r.settings.navId && r.settings.navId === 1;
                                } else {
                                    return r.settings && r.settings.level && r.settings.level === 1 && !r.settings.parentId && r.settings.navId;
                                }
                            }).sort(function (r1, r2) {
                                return r1.settings.order - r2.settings.order;
                            });
                            angular.forEach($rootScope.navRoutes, function (value) {
                                if (value.settings) {
                                    value.settings.childRoutes = states.filter(function (r) {
                                        if (DOMAIN.justPsycho === 1) {
                                            return r.settings && r.settings.level && r.settings.level === 2 && r.settings.parentId && r.settings.parentId === value.settings.navId && r.settings.navId && r.settings.navId === 24;
                                        } else {
                                            return r.settings && r.settings.level && r.settings.level === 2 && r.settings.parentId && r.settings.parentId === value.settings.navId && r.settings.navId;
                                        }
                                    }).sort(function (r1, r2) {
                                        if (r1.settings['orderTitle_' + lang]) {
                                            return r1.settings['orderTitle_' + lang].localeCompare(r2.settings['orderTitle_' + lang]);
                                        } else {
                                            return r1.settings.order - r2.settings.order;
                                        }
                                    });
                                    angular.forEach(value.settings.childRoutes, function (value) {
                                        if (value.settings) {
                                            value.settings.childRoutes = states.filter(function (r) {
                                                if (DOMAIN.justPsycho === 1) {
                                                    return r.settings && r.settings.level && r.settings.level === 3 && r.settings.parentId && r.settings.parentId === value.settings.navId && r.settings.navId && r.settings.navId === 24;
                                                } else {
                                                    return r.settings && r.settings.level && r.settings.level === 3 && r.settings.parentId && r.settings.parentId === value.settings.navId && r.settings.navId;
                                                }
                                            }).sort(function (r1, r2) {
                                                if (r1.settings['orderTitle_' + lang]) {
                                                    return r1.settings['orderTitle_' + lang].localeCompare(r2.settings['orderTitle_' + lang]);
                                                } else {
                                                    return r1.settings.order - r2.settings.order;
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                            resolve();
                        });
                    }

                    $rootScope.setLanguage = function setLanguage(language) {
                        return $q(function (resolve) {
                            var defaultLang = true;
                            for (var i = 0; i < $rootScope.availableLanguages.length; i++) {
                                if (language.toLowerCase().indexOf($rootScope.availableLanguages[i]) >= 0) {
                                    language = $rootScope.availableLanguages[i];
                                    defaultLang = false;
                                }
                            }

                            if (defaultLang) {
                                language = $rootScope.availableLanguages[0];
                            }

                            if (language.value) {
                                language = language.value;
                            }
                            $rootScope.language = language;

                            var a1 = gettextCatalog.getString('Item');
                            var a2 = gettextCatalog.getString('Items');

                            gettextCatalog.loadRemote('/languages/' + language + '.json').then(function () {
                                gettextCatalog.setCurrentLanguage(language);

                                ej2.base.L10n.load({
                                    'hu-HU': {
                                        'grid': {
                                            // 'EmptyRecord': 'Nincs megjeleníthető adat!',
                                            EmptyRecord: gettextCatalog.getString('EmptyRecord'),
                                            BatchSaveConfirm: gettextCatalog.getString('BatchSaveConfirm'),
                                            SaveButton: gettextCatalog.getString('Save'),
                                            OkButton: 'Rendben',
                                            CancelButton: gettextCatalog.getString('Cancel'),
                                            ConfirmDelete: gettextCatalog.getString('ChangesAutomaticallySaved'),
                                            BatchSaveLostChanges: gettextCatalog.getString('Unsaved changes will be lost. Are you sure you want to continue?'),
                                            Item: gettextCatalog.getString('Item'),
                                            Items: gettextCatalog.getString('Item'),
                                            GroupDropArea: gettextCatalog.getString('GroupDropArea'),
                                            Print: gettextCatalog.getString('Print'),
                                            Delete: gettextCatalog.getString('Delete'),
                                            ExcelExport: 'Excel Export',
                                            Search: gettextCatalog.getString('Search'),
                                            DatePickerWaterMark: gettextCatalog.getString('SelectTheDate'),
                                        },
                                        'pager': {
                                            currentPageInfo: '{0}. ' + gettextCatalog.getString('gridPage') + ': {1} ' + gettextCatalog.getString('gridPages'),
                                            totalItemsInfo: '(' + gettextCatalog.getString('allItems') + ': {0})',
                                            pagerDropDown: gettextCatalog.getString('RecordPerPage'),
                                            pagerInfo: gettextCatalog.getString('pagerInfo'), // "{0} {1} lapok ({2} elem)",
                                            firstPageTooltip: gettextCatalog.getString('firstPageTooltip'), //  "Ugrás az első oldalra",
                                            lastPageTooltip: gettextCatalog.getString('lastPageTooltip'), //"Ugrás az utolsó oldalra",
                                            nextPageTooltip: gettextCatalog.getString('nextPageTooltip'), //"Ugrás a következő oldalra",
                                            previousPageTooltip: gettextCatalog.getString('previousPageTooltip'), //"Ugrás az előző oldalra",
                                            nextPagerTooltip: gettextCatalog.getString('nextPagerTooltip'), //"Ugrás a következő oldalra",
                                            previousPagerTooltip: gettextCatalog.getString('previousPagerTooltip'), //"Ugrás az előző oldalra"
                                        }
                                    }
                                });

                                ej.Pager.Locale['hu-HU'] = {
                                    pagerInfo: '{0}/{1} ' + gettextCatalog.getString('Page') + ' (' + gettextCatalog.getString('ResultsNumber') + ': {2})'
                                };
                                ej.DatePicker.Locale['hu-HU'] = {
                                    watermarkText: gettextCatalog.getString('ChooseDate'),
                                    buttonText: gettextCatalog.getString('Today')
                                };
                                ej.Autocomplete.Locale['hu-HU'] = {
                                    addNewText: gettextCatalog.getString('addNewText'),// "Új hozzáadása",
                                    emptyResultText: gettextCatalog.getString('emptyResultText') // "Nincs javaslat",
                                };
                                ej.CurrencyTextbox.Locale['hu-HU'] = {
                                    watermarkText: gettextCatalog.getString('currencyTextboxWatermarkText') // "Írja be az értéket",
                                };
                                ej.DatePicker.Locale['hu-HU'] = {
                                    watermarkText: gettextCatalog.getString('datePickerWatermarkText'), //"Válassza ki a dátumot",
                                    buttonText: 'Ma',
                                };
                                ej.DateTimePicker.Locale['hu-HU'] = {
                                    watermarkText: gettextCatalog.getString('dateTimePickerWatermarkText'),// "Válassza ki a dátumot és időt",
                                    buttonText: {
                                        today: gettextCatalog.getString('today'), // "Ma",
                                        timeNow: gettextCatalog.getString('timeNow'),// "Most",
                                        done: gettextCatalog.getString('done'),//"Kész",
                                        timeTitle: gettextCatalog.getString('timeTitle')//"Idő"
                                    },
                                };
                                ej.Dialog.Locale['hu-HU'] = {
                                    tooltip: {
                                        close: gettextCatalog.getString('close'), //"Bezárás",
                                        collapse: gettextCatalog.getString('collapse'), //"Összecsuk",
                                        restore: gettextCatalog.getString('restore'), // "Kinyit",
                                        maximize: gettextCatalog.getString('maximize'), // "Teljes méret",
                                        minimize: gettextCatalog.getString('minimize'), //"Kis méret",
                                        expand: gettextCatalog.getString('expand'), //"Kinagyít",
                                    },
                                    closeIconTooltip: gettextCatalog.getString('closeIconTooltip'), //"Bezárás",
                                };
                                ej.DropDownList.Locale['hu-HU'] = {
                                    emptyResultText: gettextCatalog.getString('emptyResultText'), //"Nincsenek elemek",
                                    watermarkText: ' ',
                                };
                                ej.NumericTextbox.Locale['hu-HU'] = {
                                    watermarkText: gettextCatalog.getString('currencyTextboxWatermarkText'),
                                };
                                ej.Pager.Locale['hu-HU'] = {
                                    pagerInfo: gettextCatalog.getString('pagerInfo'), // "{0} {1} lapok ({2} elem)",
                                    firstPageTooltip: gettextCatalog.getString('firstPageTooltip'), //  "Ugrás az első oldalra",
                                    lastPageTooltip: gettextCatalog.getString('lastPageTooltip'), //"Ugrás az utolsó oldalra",
                                    nextPageTooltip: gettextCatalog.getString('nextPageTooltip'), //"Ugrás a következő oldalra",
                                    previousPageTooltip: gettextCatalog.getString('previousPageTooltip'), //"Ugrás az előző oldalra",
                                    nextPagerTooltip: gettextCatalog.getString('nextPagerTooltip'), //"Ugrás a következő oldalra",
                                    previousPagerTooltip: gettextCatalog.getString('previousPagerTooltip'), //"Ugrás az előző oldalra",
                                };
                                ej.PercentageTextbox.Locale['hu-HU'] = {
                                    watermarkText: gettextCatalog.getString('currencyTextboxWatermarkText') //  "Írja be az értéket",
                                };
                                ej.Schedule.Locale['hu'] = {
                                    Priority: gettextCatalog.getString('AlertUsers'), // 'Felhasználók értesítése'
                                    CreateAppointmentTitle: gettextCatalog.getString('CreateAppointmentTitle'), //  'Esemény létrehozása',
                                    RecurrenceEditTitle: gettextCatalog.getString('RecurrenceEditTitle'), // 'Ismétlődő esemény szerkesztése',
                                    RecurrenceEditMessage: gettextCatalog.getString('RecurrenceEditMessage'), //'Hogyan szeretné módosítani az eseményt?',
                                    RecurrenceEditOnly: gettextCatalog.getString('RecurrenceEditOnly'), //'Ezt az egyet',
                                    RecurrenceEditSeries: gettextCatalog.getString('RecurrenceEditSeries'), //'Összeset',
                                    PreviousAppointment: gettextCatalog.getString('PreviousAppointment'), //'Előző esemény',
                                    NextAppointment: gettextCatalog.getString('NextAppointment'), //'Következő esemény',
                                    AppointmentSubject: gettextCatalog.getString('AppointmentSubject'), //'Esemény neve',
                                    StartTime: gettextCatalog.getString('StartTime'), //'Esemény kezdete',
                                    EndTime: gettextCatalog.getString('EndTime'), //'Esemény vége',
                                    AllDay: gettextCatalog.getString('AllDay'), //'Egész napos',
                                    Today: gettextCatalog.getString('Today'), //'Mai nap',
                                    Recurrence: gettextCatalog.getString('Recurrence'), //'Ismétlődés gyakorisága',
                                    Done: gettextCatalog.getString('Done'), //'Kész',
                                    Cancel: gettextCatalog.getString('Cancel'), //'Mégse',
                                    Ok: gettextCatalog.getString('Ok'), //'Ok',
                                    RepeatBy: gettextCatalog.getString('RepeatBy'), //'Ismétlés',
                                    RepeatEvery: gettextCatalog.getString('RepeatEvery'), //'Ismétlés gyakorisága',
                                    Repeat: gettextCatalog.getString('Repeat'), //'Ismétlés',
                                    RepeatOn: gettextCatalog.getString('RepeatOn'), //'Ismétlés ezeken a napokon',
                                    StartsOn: gettextCatalog.getString('StartsOn'), //'Ismétlődés ettől',
                                    Ends: gettextCatalog.getString('Ends'), //'Ismétlődés vége',
                                    Summary: gettextCatalog.getString('Summary'), //'Ismétlődés',
                                    Daily: gettextCatalog.getString('Daily'), //'Naponta',
                                    Weekly: gettextCatalog.getString('Weekly'), //'Hetente',
                                    Monthly: gettextCatalog.getString('Monthly'), //'Havonta',
                                    Yearly: gettextCatalog.getString('Yearly'), //'Évente',
                                    Every: gettextCatalog.getString('Every'), //'Minden',
                                    EveryWeekDay: gettextCatalog.getString('EveryWeekDay'), //'Munkanapokon',
                                    Never: gettextCatalog.getString('Never'), //'Soha',
                                    After: gettextCatalog.getString('After'), //'A(z)',
                                    Occurrence: gettextCatalog.getString('Occurrence'), //'alkalom után',
                                    On: gettextCatalog.getString('On'), //'A következő napig',
                                    Edit: gettextCatalog.getString('Edit'), //'Módosítás',
                                    RecurrenceDay: gettextCatalog.getString('RecurrenceDay'), //'napon',
                                    RecurrenceWeek: gettextCatalog.getString('RecurrenceWeek'), //'héten',
                                    RecurrenceMonth: gettextCatalog.getString('RecurrenceMonth'), //'hónapban',
                                    RecurrenceYear: gettextCatalog.getString('RecurrenceYear'), //'évben',
                                    The: '',
                                    OfEvery: '',
                                    First: gettextCatalog.getString('First'), //'első',
                                    Second: gettextCatalog.getString('Second'), //'második',
                                    Third: gettextCatalog.getString('Third'), //'harmadik',
                                    Fourth: gettextCatalog.getString('Fourth'), //'negyedik',
                                    Last: gettextCatalog.getString('Last'), //'utolsó',
                                    WeekDay: gettextCatalog.getString('WeekDay'), //'munkanap',
                                    Subject: gettextCatalog.getString('Subject'), //'Tárgy',
                                    Categorize: gettextCatalog.getString('Categorize'), //'Kategória',
                                    OpenItem: gettextCatalog.getString('OpenItem'), //'Megtekintés',
                                    Day: gettextCatalog.getString('Day'), //'Napi',
                                    Week: gettextCatalog.getString('Week'), //'Heti',
                                    WorkWeek: gettextCatalog.getString('WorkWeek'), //'Munkahét',
                                    Month: gettextCatalog.getString('Month'), //'Havi',
                                    AddEvent: gettextCatalog.getString('AddEvent'), //'Esemény hozzáadása',
                                    Agenda: gettextCatalog.getString('Agenda'), //'Lista',
                                    Detailed: gettextCatalog.getString('Detailed'), //'Részletek',
                                    EventBeginsin: gettextCatalog.getString('EventBeginsin'), //'Az esemény kezdete',
                                    Editevent: gettextCatalog.getString('Editevent'), //'Esemény szerkesztése',
                                    Editseries: gettextCatalog.getString('Editseries'), //'Sorozat szerkesztése',
                                    Times: gettextCatalog.getString('Times'), //'alkalom',
                                    Until: gettextCatalog.getString('Until'), //'Eddig',
                                    Hours: gettextCatalog.getString('Hours'), //'óra',
                                    Minutes: gettextCatalog.getString('Minutes'), //'perc',
                                    Overdue: gettextCatalog.getString('Overdue'), //'Lejárt',
                                    Days: gettextCatalog.getString('Days'), //'nap',
                                    Event: gettextCatalog.getString('Event'), //'Esemény',
                                    Select: gettextCatalog.getString('Select'), //'Kiválaszt',
                                    Previous: gettextCatalog.getString('Previous'), //'Előző',
                                    Next: gettextCatalog.getString('Next'), //'Következő',
                                    Close: gettextCatalog.getString('Close'), //'Bezár',
                                    Delete: gettextCatalog.getString('Delete'), //'Törlés',
                                    Date: gettextCatalog.getString('Date'), //'Dátum',
                                    Gotodate: gettextCatalog.getString('Gotodate'), //'Dátumra ugrás',
                                    RecurrenceDeleteTitle: gettextCatalog.getString('RecurrenceDeleteTitle'), //'Esemény törlése',
                                    Location: gettextCatalog.getString('Location'), //'Helyszín',
                                    CreateError: gettextCatalog.getString('CreateError'), // 'Az esemény hossza nem lehet nagyobb, mint az ismétlődés gyakorisága. Állítsd rövidebbre az eseményt idejét vagy változtasd meg az ismétlődést.',
                                    StartEndError: gettextCatalog.getString('StartEndError'), //'A kezdés ideje hamarabb kell legyen, mint a befejezésé.',
                                    MouseOverDeleteTitle: gettextCatalog.getString('MouseOverDeleteTitle'), //'Esemény törlése',
                                    DeleteConfirmation: gettextCatalog.getString('DeleteConfirmation'), //'Biztosan törli az eseményt?',
                                    Time: gettextCatalog.getString('Time'), //'Időpont',
                                    NoTitle: gettextCatalog.getString('NoTitle'), //'Névtelen esemény',
                                    OverFlowAppCount: gettextCatalog.getString('OverFlowAppCount'), //'további esemény',
                                    EmptyResultText: gettextCatalog.getString('EmptyResultText'), //'Nincs ilyen a listában'
                                };
                                ej.Uploadbox.Locale['hu-HU'] = {
                                    buttonText: {
                                        upload: gettextCatalog.getString('upload'), //"Feltöltés",
                                        browse: gettextCatalog.getString('browse'), //"Tallózás",
                                        cancel: gettextCatalog.getString('cancel'), //"Törlés",
                                        close: gettextCatalog.getString('close'), //"Bezárás"
                                    },
                                    dialogText: {
                                        title: gettextCatalog.getString('title'), //"Feltöltés",
                                        name: gettextCatalog.getString('name'), //"Név",
                                        size: gettextCatalog.getString('size'), //"Méret",
                                        status: gettextCatalog.getString('status'), //"Állapot"
                                    },
                                    dropAreaText: gettextCatalog.getString('dropAreaText'), //"Dobja ide a fájlokat vagy kattintson a feltöltéshez!",
                                    filedetail: gettextCatalog.getString('filedetail'), //"A kiválasztott fájl mérete túl ndoneagy. Kérjük, válasszon kisebb méretű fájlt.",
                                    denyError: gettextCatalog.getString('denyError'), //"Fájlok #Extension kiterjesztésekkel nem megengedettek.",
                                    allowError: gettextCatalog.getString('allowError'), //"Csak #Extension kiterjesztésű fájlok  engedélyezettek.",
                                    cancelToolTip: gettextCatalog.getString('cancelToolTip'), //"Mégsem",
                                    removeToolTip: gettextCatalog.getString('removeToolTip'), //"Törlés",
                                    retryToolTip: gettextCatalog.getString('retryToolTip'), //"Próbálja újra",
                                    completedToolTip: gettextCatalog.getString('completedToolTip'), //"Befejezés",
                                    failedToolTip: gettextCatalog.getString('failedToolTip'), //"nem sikerült",
                                    closeToolTip: gettextCatalog.getString('closeToolTip'), //"Bezárás",
                                };

                                $rootScope.navSubTitles = {
                                    'Athletes': gettextCatalog.getString('Athletes')
                                };
                                $rootScope.validationTranslate = {
                                    required: gettextCatalog.getString('Required'),
                                    minlength: gettextCatalog.getString('Minlength'),
                                    maxlength: gettextCatalog.getString('Maxlength')
                                };
                                $rootScope.headTitles = {
                                    'AddAgeGroup': gettextCatalog.getString('AddAgeGroup'),
                                    'AddTeamMember': gettextCatalog.getString('AddTeamMember'),
                                    'AddTeams': gettextCatalog.getString('AddTeams'),
                                    'Administration': gettextCatalog.getString('Administration'),
                                    'AgeGroupMembersList': gettextCatalog.getString('AgeGroupMembersList'),
                                    'AgeGroupsList': gettextCatalog.getString('AgeGroupsList'),
                                    'Athletes': gettextCatalog.getString('Athletes'),
                                    'AthletesList': gettextCatalog.getString('AthletesList'),
                                    'AthletesManagement': gettextCatalog.getString('AthletesManagement'),
                                    'Business': gettextCatalog.getString('Business'),
                                    'CentralReport': gettextCatalog.getString('CentralReport'),
                                    'CentralReportAllIn': gettextCatalog.getString('CentralReportAllIn'),
                                    'CentralReportByRating': gettextCatalog.getString('CentralReportByRating'),
                                    'Dashboard': gettextCatalog.getString('Dashboard'),
                                    'DuplicatedAthletes': gettextCatalog.getString('DuplicatedAthletes'),
                                    'EditHealthType': gettextCatalog.getString('EditHealthType'),
                                    'LogIn': gettextCatalog.getString('LogIn'),
                                    'ModifyAgeGroup': gettextCatalog.getString('ModifyAgeGroup'),
                                    'ModifyTeams': gettextCatalog.getString('ModifyTeams'),
                                    'MonthlyAttendance': gettextCatalog.getString('MonthlyAttendance'),
                                    'MonthlyAttendanceList': gettextCatalog.getString('MonthlyAttendanceList'),
                                    'NewAthlete': gettextCatalog.getString('NewAthlete'),
                                    'NewUser': gettextCatalog.getString('NewUser'),
                                    'Reports': gettextCatalog.getString('Reports'),
                                    'SchoolManagement': gettextCatalog.getString('SchoolManagement'),
                                    'Search': gettextCatalog.getString('Search'),
                                    'Settings': gettextCatalog.getString('Settings'),
                                    'SportSpecificReportLists': gettextCatalog.getString('SportSpecificReportLists'),
                                    'SportSpecificTestList': gettextCatalog.getString('SportSpecificTestList'),
                                    'StatisticalReports': gettextCatalog.getString('StatisticalReports'),
                                    'TeamList': gettextCatalog.getString('TeamList'),
                                    'TeamMembersList': gettextCatalog.getString('TeamMembersList'),
                                    'TestResultsFootball': gettextCatalog.getString('TestResultsFootball'),
                                    'TestResultsHockey': gettextCatalog.getString('TestResultsHockey'),
                                    'UserGroupsManagement': gettextCatalog.getString('UserGroupsManagement'),
                                    'UserManagement': gettextCatalog.getString('UserManagement'),
                                    'AgeGroupManagement': gettextCatalog.getString('AgeGroupManagement'),
                                    'AnnualPresenceReports': gettextCatalog.getString('AnnualPresenceReports'),
                                    'EventsCalendar': gettextCatalog.getString('EventsCalendar'),
                                    'ManagementReports': gettextCatalog.getString('ManagementReports'),
                                    'SearchType': gettextCatalog.getString('SearchType'),
                                    'MessageCenter': gettextCatalog.getString('MessageCenter')
                                };

                                $rootScope.busyMessage = gettextCatalog.getString('Loading');
                                getNavRoutes().then(function () {
                                    if (!$rootScope.$$phase) {
                                        $rootScope.$apply();
                                    }
                                    resolve();
                                });
                            });
                        });
                    };
                    var lang = null;
                    if (authenticationService.isAuthenticated()) {
                        lang = localStorageService.get('_authData').language;
                    } else if (authenticationService.isSessionTimeOut()) {
                        lang = localStorageService.get('_sessionData').language;
                    } else {
                        lang = navigator.language || navigator.userLanguage;
                    }
                    $rootScope.setLanguage(lang).then(function () {
                        resolve();
                    });
                });
            });
        })
        .controller('ShellController', ShellController);

    function ShellController($rootScope, $q, config, logger, authenticationService, $state, AUTH_EVENTS, LAYOUT_EVENTS, gettextCatalog, LANG_EVENTS, $scope, routerHelper, DOMAIN, loader, $compile, $window, paramHelper, dataservice, $timeout, localStorageService, log, $interval) {
        // Declaration variables
        var vm = this;
        vm.intId = -1;
        vm.busyMessage = 'Please wait...';
        vm.isBusy = true;
        vm.password = {oldPassword: '', newPassword: '', confirmPassword: '', expiried: false};
        vm.expiresLabel = '';
        vm.authData = {};
        vm.isAuthenticated = false;
        vm.pictureBasePath = DOMAIN.baseURL + DOMAIN.profileImgBaseUrl + '/';
        vm.userPermission = authenticationService.getPermission();
        vm.logout = logout;
        vm.openPasswordDialog = openPasswordDialog2;
        vm.goToProfile = goToProfile;
        vm.goToDashboard = goToDashboard;
        vm.athleteidOfAthleteUser = -1;
        vm.partnerLogo = '';
        $rootScope.sideMenuOpened = true;
        $rootScope.gridPage = 5;
        $rootScope.gridPageSizes = [4, 8, 20, 100];

        // SYNCFUSION 2 SETTINGS
        $rootScope.toolbarItems = ['ExcelExport', /*'pdfexport',*/ 'Print'];
        $rootScope.toolbarItemsWithDelete = ['ExcelExport', /*'pdfexport',*/ 'Print', 'Delete'];
        $rootScope.toolbarItemsWithSearch = ['ExcelExport', /*'pdfexport',*/ 'Print', 'Search'];
        $rootScope.toolbarItemsWithSearchAndDelete = ['ExcelExport', /*'pdfexport',*/ 'Print', 'Delete', 'Search'];
        $rootScope.toolbarClick = function (args, grid) {
            if (args.item.id === (grid.element.id + '_excelexport')) {
                var origColumns = $.extend(true, [], grid.properties.columns);
                grid.properties.columns.forEach(function (c) {
                    c.headerText = c.headerText.replace('<br>', ' ');
                });
                if (grid.columns[0].type === 'checkbox') {
                    grid.columns[0].visible = false;
                    grid.excelExport().then(function () {
                        grid.columns[0].visible = true;
                    });
                } else {
                    grid.excelExport();
                }
                grid.properties.columns = $.extend(true, [], origColumns);
            }
            // if (args.item.id === (grid.element.id + '_pdfexport')) {
            //     grid.pdfExport();
            // }
            if (args.item.id === (grid.element.id + '_print')) {
                grid.print({pageOrientation: 'Landscape'});
            }
        };
        $rootScope.compile = function compile(args, scope) {
            $timeout(function () {
                $compile(args)(scope);
            }, 100);
        };
        // checkBoxChange event
        $rootScope.checkBoxChangeOnlyOne = function (args, grid) {
            if (args.selectedRowIndexes.length > 1) {
                var el = $(grid.element).find('.e-checkselectall').next();
                el.addClass('e-uncheck');
                el.removeClass('e-check');
                grid.clearSelection();
                log.errorMsg('YouHaveToSelectOnlyOneRow');
            }
        };
        $rootScope.getSelectedItems = function (grid) {
            var selectedRowIndexes = grid.getSelectedRowIndexes();
            var items = [];
            selectedRowIndexes.forEach(function (element) {
                items.push(grid.selectionModule.contentRenderer.rows[element].data);
            });
            return items;
        };

        $rootScope.deleteRows = function (grid) {
            var selectedRowIndexes = grid.getSelectedRowIndexes();
            var indexes = selectedRowIndexes.sort(function (a, b) {
                return b - a;
            });
            indexes.forEach(function (i) {
                grid.dataSource.splice(i, 1);
            });
            grid.refresh();
            return grid.dataSource;
        };

        $rootScope.gridSelectedRowIndex = function (grid) {
            var indexes = [];
            var selectedIndexes = grid.getSelectedRowIndexes();
            selectedIndexes.forEach(function (indexId) {
                indexes.push((grid.pageSettings.currentPage - 1) * grid.pageSettings.pageSize + indexId);
            });
            return indexes;
        };
        $rootScope.checkRowSelection = function (grid, onlyOne) {
            var selectedRows = $rootScope.getSelectedItems(grid);
            if (selectedRows.length === 0) {
                log.errorMsg('NoSelectedRow');
                return false;
            }
            if (onlyOne && selectedRows.length > 1) {
                log.errorMsg('YouHaveToSelectOnlyOneRow');
                return false;
            }
            return true;
        };

        $rootScope.beforePrintGrid = function (args, gridColumns) {
            var gridObj = args.element.ej2_instances[0]; // instance for printing Grid
            if (gridColumns) {
                gridObj.hideColumns(gridColumns, 'field');
                gridObj.refresh();
            } else {
                gridObj.hideColumns('Link', 'field');
                gridObj.refresh();
            }
        };
        $rootScope.printCompleteGrid = function (args, gridColumns) {
            var gridObj = args.element.ej2_instances[0]; // instance for printing Grid
            if (gridColumns) {
                gridObj.showColumns(gridColumns, 'field');
                gridObj.refresh();
            } else {
                gridObj.showColumn('Link', 'field');
                gridObj.refresh();
            }
        };
        // VÉGE

        $rootScope.sideMenuOpen = function () {
            $rootScope.sideMenuOpened = true;
        };

        $rootScope.sideMenuClose = function () {
            $rootScope.sideMenuOpened = false;
        };

        activate();

        function activate() {
            vm.intId = setInterval(TryRefreshToken, 1000);
            return $q(function (resolve) {
                vm.partnerLogoSmall = clientConfig.partnerLogoSmall;
                vm.partnerLogo = config.partnerLogo;
                logger.success(config.appTitle + ' loaded!', null);
                if (authenticationService.isAuthenticated()) {
                    if (localStorageService.get('_authData').gridRecordPerPage) {
                        $rootScope.gridPage = parseInt(localStorageService.get('gridRecordPerPage') || 10);
                        $timeout(function () {
                            $scope.$apply();
                        }, 0);
                    }

                    pageSettings();
                    haveToPay();
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        }

        function TryRefreshToken() {
            if (!authenticationService.isAuthenticated()) {
                return;
            }
            var sikertopic = localStorageService.get('sikertopic');
            var firebasetoken = localStorage.getItem('firebasetoken');
            var lang = authenticationService.getAuthData().language;
            if (authenticationService.isAuthenticated() && sikertopic && firebasetoken && sikertopic.length > 0 && firebasetoken.length > 0) {
                console.log('TOPIC:', sikertopic);
                console.log('TOKEN:', firebasetoken);
                console.log('LANG:', lang);
                dataservice.FirebaseSubscribeToTopic({topic: sikertopic, token: firebasetoken, lang: lang}).then(function (resp) {
                    console.log('FirebaseSubscribeToTopic', resp);
                });
                clearInterval(vm.intId);
            }
        }

        var pageSize = 4;

        function pageSettings() {
            paramHelper.getParams([], ['gridRecordPerPage']).then(function (result) {
                if (result['gridRecordPerPage']) {
                    pageSize = parseInt(result['gridRecordPerPage']);
                }
                $rootScope.pageSettings = {pageCount: 5, pageSize: pageSize, pageSizes: $rootScope.gridPageSizes};
            });
        }

        $scope.$watch('title', function () {
            $rootScope.htmlTitle = 'Siker 2.0 b201810171440 - ' + $rootScope.title;
        });

        $scope.previousState;

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
            pageSettings();
            if ($rootScope.monthlyAttendanceSaveInterval) {
                $interval.cancel($rootScope.monthlyAttendanceSaveInterval);
                $rootScope.monthlyAttendanceSaveInterval = undefined;
            }
            if (options.location === false && (fromState.url === '/eventsCalendar' || fromState.url === '/schedule')) {
                event.preventDefault();
            }
            $rootScope.actualState = toState;
            /*
            else {
                 loader.start();
            }*/
        });

        $rootScope.stateList = [];

        $rootScope.fromBack = false;
        $scope.$on('$stateChangeSuccess', function (ev, to, toParams, from) {
            if (!$rootScope.fromBack) {
                $rootScope.stateList.push(from.name);
            }
            $scope.actual = to.name;
            //$log.log($rootScope.stateList);
            $rootScope.fromBack = false;
            // loader.end();
        });
        $scope.$on('$stateNotFound', function () {
            // loader.end();
        });
        $scope.$on('stateChangeError', function () {
            // loader.end();
        });

        $rootScope.back = function () {
            $rootScope.fromBack = true;
            var state = $rootScope.stateList.pop();
            if (state === angular.isUndefined || state === '') {
                state = 'dashboard';
            }
            $state.go(state);
        };

        $rootScope.home = function () {
            $state.go('dashboard');
        };

        $rootScope.login = function () {
            $state.go('login');
            loader.end();
        };

        // //
        // $rootScope.$watch(function () {
        //     return authenticationService.isAuthenticated();
        // }, function (value) {
        //     vm.isAuthenticated = value;
        //     vm.authData = authenticationService.getAuthData();
        // });

        //Refres profile image broadcast handler
        $rootScope.$on(AUTH_EVENTS.refreshProfilePic, function (events, args) {
            vm.authData.picture = args;
        });

        function logout() {
            $rootScope.title = '';
            authenticationService.logout().then(function (result) {
                if (!result) {
                    logger.error(gettextCatalog.getString('SignOutWasUnsucessful'));
                }
            });
        }

        function openPasswordDialog2() {
            $rootScope.$broadcast(LAYOUT_EVENTS.passwordChange);
        }

        // Watching variables change
        $rootScope.$watch(function () {
            return authenticationService.isAuthenticated();
        }, function (value) {
            vm.isAuthenticated = value;
            vm.authData = authenticationService.getAuthData();
            passwordDialogConf();
        });

        //
        function haveToPay() {
            dataservice.getHaveToPay().then(function (haveToPayResult) {
                if (haveToPayResult.retValue == true) {
                    if (!localStorageService.get('marMegnezte')) {
                        swal({
                            title: gettextCatalog.getString('Message'),
                            text: gettextCatalog.getString('AttenzioneDebitBalance'),
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            confirmButtonColor: '#DD6B55',
                            confirmButtonText: gettextCatalog.getString('Ok'),
                            onOpen: function () {
                                swal.showLoading();
                                $timeout(function () {
                                    swal.hideLoading();
                                    swal.enableButtons();
                                }, 3000);
                            }
                        }).then(function () {
                            localStorageService.set('marMegnezte', {
                                value: true,
                            });
                        });
                    }
                }
            });
        }

        // Boradcast or emitt handlers
        $rootScope.$on(AUTH_EVENTS.login, function (events, args, permission) {
            haveToPay();
            if (args === '' || args === 'login') {
                switch (permission) {
                    case '5':
                        $state.go('parentDashboard');
                        break;
                    case '6':
                        $state.go('athleteDashboard');
                        break;
                    default:
                        $state.go('dashboard');
                        break;
                }
            } else {
                $state.go(args);
            }
        });

        $rootScope.$on(AUTH_EVENTS.logout, function () {
            $state.go('login');
        });

        $rootScope.$on(AUTH_EVENTS.notAuthorized, function () {
            authenticationService.logout();
        });

        $rootScope.$on(LAYOUT_EVENTS.passwordChange, function () {
            passwordDialogConf();
            openPasswordDialog();
        });

        $rootScope.$on(LANG_EVENTS.changeLanguage, function (events, args) {
            $rootScope.setLanguage(args);
        });

        $rootScope.$on(AUTH_EVENTS.sessionTimeout, function () {
            if (!vm.isAuthenticated) {
                return;
            }
            authenticationService.setSessionTimeOut($state.current.name);
        });
        $(document).click(function(e) {
            if ($(e.target).is('#mainContent *') && window.innerWidth < 768) {
                $rootScope.$broadcast('autoCloseSideBar');
            }
        });

        function passwordDialogConf() {
            if (vm.authData) {
                var today = new Date();
                today.setHours(0, 0, 0, 0);
                var expiration = new Date(moment(vm.authData.pwdExpiration));
                expiration.setHours(0, 0, 0, 0);
                var diffDays = moment(vm.authData.pwdExpiration).diff(today, 'days');

                if (diffDays === 1) {
                    var message = gettextCatalog.getString('YourPasswordIsAboutToExpire1') + ' ' + moment(vm.authData.pwdExpiration).format('YYYY-MM-DD');
                    logger.info(message);
                    vm.expiresLabel = message;
                    openPasswordDialog();
                } else if (expiration <= today) {
                    vm.password.expiried = true;
                    var message = gettextCatalog.getString('YourPasswordExpired');
                    vm.expiresLabel = message;
                    openPasswordDialog();
                }
            }
        }

        function openPasswordDialog() {
            swal({
                title: gettextCatalog.getString('ChangePassword'),
                html: '<div id="passwordChangeModal"><p ng-show="vm.expiresLabel.length !== 0" style="color: red;" id="expiresLabel">{{vm.expiresLabel}}</p>' +
                '<form method="post">' +
                '<div class="form-group">' +
                '<input class="form-control" placeholder="Régi jelszó" name="oldPassword" id="oldPassword" autofocus data-ng-model="vm.password.oldPassword" type="password" required tabindex="1">' +
                '</div>' +
                '<div class="form-group">' +
                '<input class="form-control" placeholder="Új jelszó" name="newPassword" id="newPassword" data-ng-model="vm.password.newPassword" type="password" tabindex="2">' +
                '</div>' +
                '<div class="form-group">' +
                '<input class="form-control" placeholder="Új jelszó mégegyszer" name="confirmPassword" id="confirmPassword" data-ng-model="vm.password.confirmPassword" type="password" tabindex="3">' +
                '</div>' +
                '</form>' +
                '</div>',
                preConfirm: function () {
                    return new Promise(function (resolve) {
                        vm.password.newPassword = document.getElementById('newPassword').value;
                        vm.password.oldPassword = document.getElementById('oldPassword').value;
                        vm.password.confirmPassword = document.getElementById('confirmPassword').value;

                        if (!vm.password.newPassword || vm.password.newPassword.length < 4 || vm.password.newPassword.length > 10) {
                            swal.showValidationError(gettextCatalog.getString('PasswordMustBeAtLeast5CharactersLong'));
                        }
                        if (vm.password.oldPassword.length === 0 || vm.password.newPassword.length === 0 || vm.password.confirmPassword.length === 0) {
                            swal.showValidationError(gettextCatalog.getString('FellInAllElement'));
                        }
                        if (vm.password.newPassword !== vm.password.confirmPassword) {
                            swal.showValidationError(gettextCatalog.getString('PasswordsDontMatch'));
                        }
                        resolve();
                    });
                },
                showCancelButton: !vm.password.expiried,
                allowEscapeKey: false,
                allowOutsideClick: false,
                confirmButtonText: gettextCatalog.getString('OK'),
                cancelButtonText: gettextCatalog.getString('Cancel')
            }).then(function (confirmed) {
                if (confirmed.value) {
                    $window.onkeydown = null;
                    $window.onfocus = null;
                    if (confirmed) {
                        vm.error = '';
                        dataservice.changePassword(vm.password.oldPassword, vm.password.newPassword)
                            .then(function (data) {
                                if (data.retValue === true && data.error.length === 0) {
                                    var expiration = new Date();
                                    vm.authData.expiration = expiration.setFullYear(expiration.getFullYear() + 1);
                                    logger.success(gettextCatalog.getString('PasswordChanged'));
                                    vm.password = {
                                        oldPassword: '',
                                        newPassword: '',
                                        confirmPassword: '',
                                        expiried: false
                                    };
                                    vm.expiresLabel = '';
                                    swal.close();
                                    logout();
                                }
                            }).catch(function () {
                            logger.error(gettextCatalog.getString('SaveUnsuccessful'));
                            openPasswordDialog();
                        });
                    } else {
                        vm.password = {oldPassword: '', newPassword: '', confirmPassword: '', expiried: false};
                        vm.expiresLabel = '';
                    }
                }
            });

            $compile(angular.element('#passwordChangeModal'))($scope);
            angular.element('.confirm').removeAttr('tabindex');
            angular.element('#oldPassword').attr('tabindex', 1);
            angular.element('#newPassword').attr('tabindex', 2);
            angular.element('#confirmPassword').attr('tabindex', 3);
        }

        function openAthleteModify() {
            dataservice.getAthleteidBelongsToAthleteUser()
                .then(function (data) {
                    vm.athleteidOfAthleteUser = data.data;
                    paramHelper.setParams([
                        {'athleteModify.sportoloId': vm.athleteidOfAthleteUser},
                        {'athleteModify.sportoloNev': vm.authData.loginName}
                    ]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                });
        }

        function goToProfile() {
            $q.all([authenticationService.getRight('SPORTOLO')])
                .then(() => {
                    $state.go('settings.userManagement.userSettings');
                });
        }

        function goToDashboard() {
            var permission = authenticationService.getPermission();
            switch (permission) {
                case '5':
                    $state.go('parentDashboard');
                    break;
                case '6':
                    $state.go('athleteDashboard');
                    break;
                default:
                    $state.go('dashboard');
                    break;
            }
        }

        loader.end();
    }
})();
